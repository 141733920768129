// @flow
import { useCallback, useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import withGroupClass from '@hoc/withGroupClass';

import ParticipantsLayout from '@layout/components/ParticipantsLayout';
import Toast from '_common/services/Toast/Toast';
import ParticipantIndicator from '@shared/ParticipantIndicator';
import Button, { buttonConstants } from '@shared/Button';
import FormContainer from '@shared/FormContainer';
import PersonalForm from '@shared/Personal/components/PersonalForm';
import IconChevron from '@icons/components/IconChevron';

import ParticipantService from '@participant/services/ParticipantService';

import type { Participant } from '@participant/types';
import type { GroupClassDetailed } from '@groupClass/types';
import type { Error } from '@core/types';

import { ERROR_IS_BLANK } from '@helpers/TranslationHelpers';
import { PERSON_TYPE_PARTICIPANT } from '@person/constants';
import { EMPTY_PARTICIPANT } from '@participant/constants';
import { WEB_PATHS } from '@app/constants/paths';
import { validateParticipantForm } from '@participant/helpers/ParticipantsUtils';
const { PARTICIPANTS_SEARCH, PARTICIPANTS_ADD_PERSON_BY_ID_PAYMENT } = WEB_PATHS;

type Props = {
  groupClass: GroupClassDetailed | null,
};

export const CreateParticipant = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const [ isSaving ] = useState<boolean>(false);
  const [ participant, setParticipant ] = useState<Participant>(EMPTY_PARTICIPANT);
  const [ errors, setErrors ] = useState<Error[]>([]);
  const [ authorizeData, setAuthorizeData ] = useState<boolean>(false);
  const [ isLoadingCreateParticipant, setIsLoadingCreateParticipant ] = useState<boolean>(false);

  const toggleAuthorizeData = useCallback((): void => {
    setAuthorizeData(!authorizeData);
  }, [authorizeData]);

  const firstName = useMemo((): string => (
    participant.firstName
  ), [participant.firstName]);

  const lastNameUpperCased = useMemo((): string => (
    participant.lastName.toUpperCase()
  ), [participant.lastName]);

  const validateAndSubmit = useCallback((): void => {
    setIsLoadingCreateParticipant(true);
    const formErrors = validateParticipantForm(participant);

    if (!authorizeData) {
      formErrors.push({ propertyPath: 'authorizeData', code: ERROR_IS_BLANK, message: '' });
    }

    setErrors(formErrors);

    if (formErrors.length === 0) {
      const newParticipant = {
        ...participant,
        authorizeData,
        type: PERSON_TYPE_PARTICIPANT,
      };

      ParticipantService.create(newParticipant)
        .then((res) => {
          Toast.success(t('groupClasses.groupClass.peoples.form.personCreated'));
          navigate(PARTICIPANTS_ADD_PERSON_BY_ID_PAYMENT
            .replace(':classId', String(groupClass?.id))
            .replace(':personId', String(res.id))
            .replace(':personType', PERSON_TYPE_PARTICIPANT));
        })
        .catch(() => {
          setErrors(ParticipantService.errorsValues);
        })
        .finally(() => setIsLoadingCreateParticipant(false));
    }
  }, [participant, t, groupClass, authorizeData]);

  const footerButtons: React$Node = useMemo((): React$Node => (
    <div className="button-edit-mode">
      <Button
        className="button-position"
        isLoading={ isLoadingCreateParticipant }
        onClick={ validateAndSubmit }
        type={ buttonConstants.PRIMARY }
      >
        { t('groupClasses.groupClass.peoples.form.next') }
      </Button>
    </div>
  ), [validateAndSubmit, language, isLoadingCreateParticipant]);

  return (
    <ParticipantsLayout groupClass={ groupClass }>
      <>
        <Link
          to={ groupClass ? PARTICIPANTS_SEARCH.replace(':classId', String(groupClass.id)) : '' }
          state={ { keepSearch: true } }
          className="button button-tertiary button-position mb-5"
        >
          <IconChevron className="back-icon" />
          { t('groupClasses.groupClass.peoples.form.cancelAddToGroupClass') }
        </Link>
        <FormContainer
          title={
            <>
              <ParticipantIndicator isBeneficiary={ false } extraCss="participant-tab" />
              { `${ firstName } ${ lastNameUpperCased }` }
            </>
          }
          className="beneficiary-request-card"
          isLoading={ isSaving }
          footerButtonActions={ footerButtons }
        >
          <div className="block-infos">
            <div className="block-title">
              <h2 className="title-two">
                { t('groupClasses.groupClass.peoples.form.personalInfosTitle') }
              </h2>
            </div>
            <div className="separator" />
            <div className="container">
              <div className="columns">
                <div className="column is-12">
                  <div className="content is-open" >
                    <PersonalForm
                      user={ participant }
                      setUser={ setParticipant }
                      errors={ errors }
                      authorizeData={ authorizeData }
                      toggleAuthorizeData={ toggleAuthorizeData }
                      groupClass={ groupClass }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FormContainer>
      </>
    </ParticipantsLayout>
  );
};

export default withGroupClass(CreateParticipant);
