import React, { useState, useEffect, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { WEB_PATHS } from '@app/constants/paths';
const { PAGE_CONTACT } = WEB_PATHS;

const HubspotEmbeddedForm = ({ formId, fallbackUrl, children }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [formCreated, setFormCreated] = useState(false);
  const modalClassName = useMemo(() => clsx('modal', modalIsOpen && 'is-active'), [modalIsOpen]);

  const handleContactForm = () => {

    if (formCreated) {
      setIsOpen(true);
      return;
    }

    if (formId) {
      openHubspotForm(formId);
      setFormCreated(true);
      setIsOpen(true);
    }

    if (!formId) {
      window.location.href = fallbackUrl || PAGE_CONTACT;
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openHubspotForm = useCallback((formId) => {
    if (window.hbspt?.forms && formId) {
      window.hbspt.forms.create({
        region: 'eu1',
        portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID,
        formId,
        target: '#hubspotForm',
        onFormReady: (form) => {

          setTimeout(() => {
            // Add the prescriber to the form as a hidden input.
            // This is used in HubSpot automation to determine
            // which person at SielBleu should be contacted.
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = 'prescriber';
            input.value = 'mcsb';
            form.appendChild(input);
          }, 500);
        },
      });
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <a href="" onClick={ (event) => {
        event.preventDefault();
        handleContactForm();
      } }>
        { children }
      </a>
      <div className={ modalClassName }>
        <div className="modal-background" onClick={ closeModal }></div>
        <div className="modal-content box has-background-white p-5">
          <button className="delete is-large" aria-label="close" onClick={ closeModal }></button>
          <div className="pt-0" id="hubspotForm">
          </div>
        </div>
      </div>
    </>
  );
};

export default HubspotEmbeddedForm;
